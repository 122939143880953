import React, { useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { COLOR_CONSTANTS, theme } from 'theme'
import {
  LIGHT_THEME,
  COLOR_THEME_OPTIONS,
  LOCAL_STORAGE_KEY_THEME,
  SYSTEM_THEME,
  DARK_THEME,
  LOCAL_STORAGE_KEY_DIRECTION,
  DIRECTION_RIGHT_TO_LEFT,
} from 'consts'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Button from 'components/atoms/Button'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import Modal from 'shared/Modal'

const withConfirm = (Component) => {
  const WithConfirmComponent = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [fn, setFn] = useState()
    const [cancelFn, setCancelFn] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingCancel, setIsLoadingCancel] = useState(false)
    const [message, setMessage] = useState('Are you sure?')
    const [action, setAction] = useState('Remove')
    const [cancel, setCancel] = useState('Cancel')

    const [selectedTheme, setSelectedTheme] = useState({
      ...theme,
      colors: { ...COLOR_THEME_OPTIONS[LIGHT_THEME] },
      theme_name: LIGHT_THEME,
      theme_color: LIGHT_THEME,
      isRTL: false,
    })
    const [changeThemeTo, setChangeThemeTo] = useState(null)
    const [changeDirectionTo, setChangeDirectionTo] = useState(null)

    useEffect(() => {
      if (changeThemeTo) {
        const { theme_name, theme_color } = changeThemeTo

        if (selectedTheme.theme_name === theme_name) {
          delete selectedTheme.must_not_auto_change
        } else {
          selectedTheme.must_not_auto_change = true
        }

        selectedTheme.colors = { ...COLOR_THEME_OPTIONS[theme_color] }

        selectedTheme.theme_name = theme_name
        selectedTheme.theme_color = theme_color
        setSelectedTheme({ ...selectedTheme })

        setChangeThemeTo(null)
      }
    }, [changeThemeTo])

    useEffect(() => {
      if (changeDirectionTo) {
        const { direction } = changeDirectionTo

        selectedTheme.isRTL = direction === DIRECTION_RIGHT_TO_LEFT

        setSelectedTheme({ ...selectedTheme })

        setChangeThemeTo(null)
      }
    }, [changeDirectionTo])

    const checkAndSetTheme = () => {
      if (!selectedTheme.must_not_auto_change) {
        const localStorageTheme = localStorage.getItem(LOCAL_STORAGE_KEY_THEME)

        const localStorageDirection = localStorage.getItem(LOCAL_STORAGE_KEY_DIRECTION)

        let user_theme_color = LIGHT_THEME
        let user_theme_name = LIGHT_THEME

        if (localStorageTheme) {
          user_theme_color = localStorageTheme
          user_theme_name = localStorageTheme
        }

        if (user_theme_color === SYSTEM_THEME && process.browser) {
          const system_match = window.matchMedia('(prefers-color-scheme: dark)')

          user_theme_name = SYSTEM_THEME
          user_theme_color = system_match.matches ? DARK_THEME : LIGHT_THEME
        }

        let theme_needs_update = false

        if (
          user_theme_color &&
          COLOR_THEME_OPTIONS[user_theme_color] &&
          (selectedTheme.theme_name !== user_theme_name || selectedTheme.theme_color !== user_theme_color)
        ) {
          selectedTheme.colors = { ...COLOR_THEME_OPTIONS[user_theme_color] }

          selectedTheme.theme_name = user_theme_name
          selectedTheme.theme_color = user_theme_color

          theme_needs_update = true
        }

        if (localStorageDirection && localStorageDirection === DIRECTION_RIGHT_TO_LEFT && !selectedTheme.isRTL) {
          theme_needs_update = true
          selectedTheme.isRTL = true
        }

        if (theme_needs_update) {
          setSelectedTheme({ ...selectedTheme })
        }
      }
    }

    const handleChangeThemeInWithConfirm = (event) => {
      const { detail = {} } = event
      const { theme_color, theme_name } = detail || {}

      setChangeThemeTo({ ...{ theme_name, theme_color } })
    }

    const handleChangeDirectionInWithConfirm = (event) => {
      const { detail = {} } = event
      const { direction } = detail || {}

      setChangeDirectionTo({ ...{ direction } })
    }

    useEffect(() => {
      checkAndSetTheme()

      window.addEventListener('handleChangeThemeInWithConfirm', handleChangeThemeInWithConfirm)
      window.addEventListener('handleChangeDirectionInWithConfirm', handleChangeDirectionInWithConfirm)

      return () => {
        window.removeEventListener('handleChangeThemeInWithConfirm', handleChangeThemeInWithConfirm)
        window.removeEventListener('handleChangeDirectionInWithConfirm', handleChangeDirectionInWithConfirm)
      }
    }, [])

    return (
      <ThemeProvider theme={selectedTheme}>
        <React.Fragment>
          <Component
            confirm={({ fn, message, action, cancel, cancelFn }) => {
              checkAndSetTheme()

              setAction(action)
              setFn(fn)
              setMessage(message)
              if (cancel !== undefined) {
                setCancel(cancel)
              }
              if (cancelFn) {
                setCancelFn(cancelFn)
              }
              setIsModalOpen(true)
            }}
            {...props}
          />

          {isModalOpen && (
            <Modal
              isOpen={isModalOpen}
              handleDismiss={() => {
                setIsModalOpen(false)
              }}
              isDisabled={isLoading || isLoadingCancel}
              theme={selectedTheme}
            >
              <Text dangerouslySetInnerHTML={{ __html: message }} />
              <Flex mt="l" justifyContent="flex-end">
                <Box>
                  {cancel && (
                    <ButtonWithLoading
                      buttonComp={Button.Gray}
                      loaderColor={COLOR_CONSTANTS.SALUTE}
                      isLoading={isLoadingCancel}
                      onClick={async () => {
                        if (cancelFn) {
                          setIsLoadingCancel(true)
                          await cancelFn()
                          setIsLoadingCancel(false)
                        }
                        setIsModalOpen(false)
                      }}
                      isSmall
                    >
                      <Text>{cancel}</Text>
                    </ButtonWithLoading>
                  )}
                </Box>
                {action && (
                  <ButtonWithLoading
                    ml="m"
                    isLoading={isLoading}
                    onClick={async () => {
                      setIsLoading(true)
                      await fn()
                      setIsLoading(false)
                      setIsModalOpen(false)
                    }}
                    isSmall
                  >
                    <Text>{action}</Text>
                  </ButtonWithLoading>
                )}
              </Flex>
            </Modal>
          )}
        </React.Fragment>
      </ThemeProvider>
    )
  }

  WithConfirmComponent.getInitialProps = (context) => {
    if (Component.getInitialProps) {
      return Component.getInitialProps(context)
    } else {
      return {}
    }
  }
  return WithConfirmComponent
}

export default withConfirm
