import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { fontSizes } from 'theme'
import { truncateHTML } from 'helpers'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import ReadMoreModal from 'components/organisms/ReadMoreModal'

const CollapseWrapper = styled(Text)`
  cursor: pointer;
`

const DEFAULT_READ_MORE_MODAL_OPTIONS = {
  isOpen: false,
  text: '',
  textForCopy: '',
}

const HtmlCollapse = ({
  html,
  textForCopy,
  maxLength,
  expandText,
  collapseText,
  textColor,
  useModalPreview,
  title,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [readMoreModalOptions, setReadMoreModalOptions] = useState({ ...DEFAULT_READ_MORE_MODAL_OPTIONS })

  const truncatedHtml = truncateHTML({ text: html, end: maxLength })
  const messageEnd = html && html.length > maxLength ? html.substr(maxLength) : ''

  const { fontSize = fontSizes.s } = props

  useEffect(() => {
    setMessage(truncatedHtml)
  }, [truncatedHtml])

  const updateMessage = (e) => {
    e.stopPropagation()

    if (useModalPreview) {
      setReadMoreModalOptions({ ...{ isOpen: true, text: html, textForCopy } })
    } else {
      if (!isOpen) {
        setMessage(html)
      } else {
        setMessage(truncatedHtml)
      }
      setIsOpen(!isOpen)
    }
  }

  const handleClickCloseReadMoreModal = () => {
    setReadMoreModalOptions({ ...DEFAULT_READ_MORE_MODAL_OPTIONS })
  }

  return (
    <Box height="100%">
      <Flex justifyContent="center" flexDirection="column">
        <Text
          dangerouslySetInnerHTML={{
            __html: message,
          }}
          {...props}
        />
      </Flex>
      {messageEnd && !isOpen && (
        <CollapseWrapper onClick={updateMessage} color={textColor} fontSize={fontSize}>
          {` ${expandText}`}
        </CollapseWrapper>
      )}

      {isOpen && (
        <CollapseWrapper onClick={updateMessage} color={textColor} fontSize={fontSize}>
          {` ${collapseText}`}
        </CollapseWrapper>
      )}

      {readMoreModalOptions.isOpen && (
        <ReadMoreModal
          isOpen={readMoreModalOptions.isOpen}
          handleDismiss={handleClickCloseReadMoreModal}
          text={readMoreModalOptions.text}
          textForCopy={readMoreModalOptions.textForCopy}
          title={title}
        />
      )}
    </Box>
  )
}

HtmlCollapse.defaultProps = {
  textForCopy: '',
  title: '',
  maxLength: 250,
  expandText: 'Read more',
  collapseText: 'Read less',
  textColor: 'primary',
  fontSize: fontSizes.s,
  useModalPreview: false,
}

HtmlCollapse.propTypes = {
  html: PropTypes.string.isRequired,
  textForCopy: PropTypes.string,
  title: PropTypes.string,
  maxLength: PropTypes.number,
  expandText: PropTypes.string,
  collapseText: PropTypes.string,
  textColor: PropTypes.string,
  fontSize: PropTypes.string,
  useModalPreview: PropTypes.bool,
}

export default HtmlCollapse
