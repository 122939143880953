import React, { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import Modal from '@material-ui/core/Modal'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Alert from 'react-s-alert'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { Formik } from 'formik'
import * as Yup from 'yup'
import ClipLoader from 'react-spinners/ClipLoader'
import { radius, space } from 'theme'
import { ERROR_MESSAGE } from 'consts'
import { pxToRem } from 'helpers'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import DropDown from 'shared/DropDown'
import { Box, Flex } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import { H4, Text } from 'components/atoms/Typography'
import Button from 'components/atoms/Button'
import TextArea from 'components/atoms/TextArea'
import Icon from 'components/atoms/Icon'
import Switch from 'components/atoms/Switch'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import Tooltip from 'components/molecules/Tooltip'
import { ROUTE_AI } from 'routes/Calendar/consts'
import { DEFAULT_POLICY } from '../consts'

const StyledDialogOverlay = styled(Modal)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001 !important;
    > * {
      &:first-child {
        background: none !important;
      }
    }
  }
`

const StyledDialogContent = styled(Flex)`
  &&& {
    position: relative;
    max-height: 682px;
    height: 100%;
    min-width: 560px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    background: transparent;
    top: 50%;
    transform: translate(0, -50%);
  }
`

const StyledDialogBodyWrapper = styled(Flex)`
  outline: none;
  height: 100%;
  overflow: hidden;
`

const StyledLeftColumnWrapper = styled(Flex)`
  outline: none;
  background-color: ${({ theme }) => theme.colors.background_card};
  border-radius: ${radius.l};
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledComplianceTextArea = styled(TextArea)`
  margin-top: 0;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const StyledAccordion = styled(Accordion)`
  background: ${({ theme }) => theme.colors.background} !important;
  &:before {
  }
  padding: 0;

  .MuiAccordionDetails-root {
    padding: 0;
    color: ${({ theme }) => theme.colors.primaryText};
  }
`

const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0 !important;
  min-height: 48px !important;
  .MuiAccordionSummary-content {
    margin: ${space.m} 0 !important;
  }
`

const StyledArrowWrapper = styled(Flex)`
  width: 24px;
  height: 24px;
  border-radius: ${radius.pill};
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;
  justify-content: center;
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`

const StyledComplianceWrapper = styled(Flex)`
  padding: ${space.s};
  border-radius: ${radius.l};
  background: ${({ theme }) => theme.colors.background_card};
  &:hover {
    box-shadow: 0 0 5px 2px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  }
`

const StyledUseComplianceWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: ${radius.l};
  padding: ${space.xs} ${space.m};
  &:hover {
    background: ${({ theme }) => theme.colors.background};
    svg {
      fill: ${({ theme }) => theme.colors.error};
    }
  }
`

const StyledText = styled(Text)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const RemoveIconWrapper = styled(Box)`
  position: absolute;
  top: -9px;
  right: -6px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const StyledGeneratedText = styled(Text)`
  white-space: break-spaces;
`

const StyledLink = styled(Text)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
`

const { PROHIBIT_DEROGATORY_LANGUAGE, PROHIBIT_PROFANITY, PROHIBIT_OFFENSIVE_LANGUAGE, ADDITIONAL_REQUIREMENTS } = {
  PROHIBIT_DEROGATORY_LANGUAGE: 'prohibit_derogatory_language',
  PROHIBIT_PROFANITY: 'prohibit_profanity',
  PROHIBIT_OFFENSIVE_LANGUAGE: 'prohibit_offensive_language',
  ADDITIONAL_REQUIREMENTS: 'additional_requirements',
}

const DEFAULT_FALSE_POSITIVE_VALUES = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
]

const ValidationSchema = () => {
  const data = {
    [ADDITIONAL_REQUIREMENTS]: Yup.string().max(
      1000,
      'Additional requirements are too long - should be 1000 chars maximum.'
    ),
  }

  return Yup.object().shape(data)
}

const ComplianceModal = ({ isOpen, handleDismiss, data }) => {
  const textAreaRef = useRef(null)
  const formRef = useRef(null)
  const scrollbarsRef = useRef(null)

  const [isGettingUsage, setIsGettingUsage] = useState(true)
  const [isGettingData, setIsGettingData] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [usage, setUsage] = useState(null)
  const [isFormExpanded, setIsFormExpanded] = useState(true)
  const [compliances, setCompliances] = useState([])
  const [useCompliance, setUseCompliance] = useState(data.active)
  const [isAIPolicyGeneratorOpen, setIsAIPolicyGeneratorOpen] = useState(!data.policy)

  const { remaining } = usage || {}

  const getUsage = async () => {
    try {
      const response = await request({
        method: 'GET',
        path: `${ROUTE_AI}/usage`,
      })
      const { error, data } = response || {}
      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        const { type } = data || {}
        if (type === 'total') {
          setUsage({ ...data })
        }
      }
    } catch (error) {
      errorHelper({ error, componentName: ComplianceModal.displayName, functionName: 'getUsage' })
    } finally {
      setIsGettingUsage(false)
    }
  }

  useEffect(() => {
    getUsage()
  }, [])

  const handleClickGenerateCompliance = async (values) => {
    try {
      setIsGettingData(true)

      const body = {
        [PROHIBIT_DEROGATORY_LANGUAGE]: values[PROHIBIT_DEROGATORY_LANGUAGE].value,
        [PROHIBIT_PROFANITY]: values[PROHIBIT_PROFANITY].value,
        [PROHIBIT_OFFENSIVE_LANGUAGE]: values[PROHIBIT_OFFENSIVE_LANGUAGE].value,
        [ADDITIONAL_REQUIREMENTS]: values[ADDITIONAL_REQUIREMENTS],
      }

      const response = await request({
        method: 'POST',
        path: `${ROUTE_AI}/compliance-ai-generator`,
        body,
      })

      const { error, caption } = response || {}
      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        setIsFormExpanded(false)
        const data = {
          id: new Date().getTime(),
          text: caption,
        }

        compliances.push(data)
        setCompliances([...compliances])
      }
    } catch (error) {
      errorHelper({ error, componentName: ComplianceModal.displayName, functionName: 'handleClickGenerateCompliance' })
    } finally {
      setIsGettingData(false)
    }
  }

  const handleClickSubmitForm = async () => {
    formRef.current.handleSubmit()
  }

  const handleClickClearGeneratedCompliances = () => {
    setCompliances([])
    setIsFormExpanded(true)
  }

  const handleClickUseCompliance = ({ text }) => {
    textAreaRef.current.value += textAreaRef.current.value ? `\n${text}` : text
  }

  const handleClickRemoveGeneratedCompliance = ({ id }) => {
    const foundIndex = compliances.findIndex((compliance) => compliance.id === id)

    if (foundIndex > -1) {
      compliances.splice(foundIndex, 1)
      setCompliances([...compliances])

      if (compliances.length === 0) {
        setIsFormExpanded(true)
      }
    }
  }

  const handleClickUseDefaultPolicy = () => {
    textAreaRef.current.value += textAreaRef.current.value ? `\n${DEFAULT_POLICY}` : DEFAULT_POLICY
  }

  const handleClickSaveEntity = async () => {
    try {
      setIsSaving(true)

      const policy = textAreaRef.current.value.trim()

      const active = policy ? useCompliance : false

      const response = await request({
        method: 'PATCH',
        path: `entities/${data.entity_gid}/compliance`,
        body: { policy, active },
      })

      if (response) {
        if (response.error) {
          Alert.error(response.error)
        } else {
          Alert.success('Brand safety and compliance policy has been updated.', { timeout: 5000 })
          // eslint-disable-next-line no-use-before-define
          handleDismiss({ policy, active })
        }
      }
    } catch (error) {
      errorHelper({ error, componentName: ComplianceModal.displayName, functionName: 'handleCollectAndSubmitData' })
    } finally {
      setIsSaving(false)
    }
  }

  const handleClickCloseModal = () => {
    handleDismiss()
  }

  return (
    <StyledDialogOverlay disableEnforceFocus open={isOpen} onClose={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent maxWidth={!isAIPolicyGeneratorOpen ? '560px' : '1132px'}>
          <StyledLeftColumnWrapper
            flexDirection="column"
            width={{ mobile: '100%', desktop: !isAIPolicyGeneratorOpen ? '100%' : '50%' }}
            height="100%"
            className="modal-content-shadow"
          >
            <StyledDialogEnvironmentWrapper px="m" justifyContent="space-between" alignItems="center" $isTop>
              <H4 my="m">Brand Safety and Compliance Policy</H4>

              <Flex alignItems="center">
                <Flex flexDirection="column">
                  <Switch
                    isOn={useCompliance}
                    onClick={() => {
                      const { value = '' } = textAreaRef.current

                      if (!useCompliance && value.trim().length === 0) {
                        Alert.error('Please type policy text below to be able to switch it on.', { timeout: 5000 })
                      } else {
                        setUseCompliance(!useCompliance)
                      }
                    }}
                    text="Active"
                    fontWeight="normal"
                  />
                </Flex>

                <Tooltip
                  width="200px"
                  message={isAIPolicyGeneratorOpen ? 'Close AI Policy Generator' : 'AI Policy Generator'}
                  // right="unset"
                  // left="0px"
                  isTriangleVisible={false}
                  wrapperComp={
                    <StyledArrowWrapper
                      ml="m"
                      onClick={() => {
                        setIsAIPolicyGeneratorOpen(!isAIPolicyGeneratorOpen)
                      }}
                    >
                      {isAIPolicyGeneratorOpen ? (
                        <Fragment>
                          <Icon.VistaSocialDashboardArrow width="14px" height="14px" fill="primary_text_reverted" />
                        </Fragment>
                      ) : (
                        <Image source="/assets/magic_wand.svg" width="22px" height="22px" />
                      )}
                    </StyledArrowWrapper>
                  }
                />
              </Flex>
            </StyledDialogEnvironmentWrapper>

            <StyledDialogBodyWrapper className="modal-content-shadow">
              <Scrollbars universal autoHide>
                <Flex width="100%" height="100%" flexDirection="column" position="relative" p="m">
                  <StyledComplianceTextArea
                    ref={textAreaRef}
                    defaultValue={data.policy || ''}
                    onChange={(e) => {
                      const { value = '' } = e.target

                      if (!isAIPolicyGeneratorOpen && !value) {
                        setIsAIPolicyGeneratorOpen(true)
                      }
                    }}
                    placeholder="Enter brand safety and compliance policy here."
                    rows={28}
                  />
                </Flex>
              </Scrollbars>
            </StyledDialogBodyWrapper>

            <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" alignItems="center" $isBottom>
              <StyledLink onClick={handleClickUseDefaultPolicy}>Use default policy</StyledLink>

              <ButtonWithLoading isSmall onClick={handleClickSaveEntity} isLoading={isSaving}>
                {isSaving ? 'Saving' : 'Save'}
              </ButtonWithLoading>
            </StyledDialogEnvironmentWrapper>
          </StyledLeftColumnWrapper>

          {isAIPolicyGeneratorOpen && (
            <Flex
              minWidth="560px"
              maxWidth="560px"
              height="100%"
              flexDirection="column"
              bg="background"
              width="50%"
              borderRadius={radius.l}
              ml="m"
            >
              <Flex flexDirection="column" height="100%">
                {isGettingUsage ? (
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                    width="100%"
                    className="cliploader-wrapper"
                  >
                    <ClipLoader size="50" />
                  </Flex>
                ) : (
                  <Fragment>
                    <Scrollbars universal ref={scrollbarsRef} autoHide>
                      <Flex px="m" flexDirection="column" height="100%">
                        <StyledAccordion defaultExpanded expanded={isFormExpanded}>
                          <StyledAccordionSummary
                            onClick={() => {
                              setIsFormExpanded(!isFormExpanded)
                            }}
                            expandIcon={
                              <StyledArrowWrapper>
                                <Icon.VistaSocialChevronDown />
                              </StyledArrowWrapper>
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                            <Text fontSize="l" color="primaryText">
                              AI Policy Generator 🪄
                            </Text>
                          </StyledAccordionSummary>
                          <AccordionDetails>
                            <Formik
                              initialValues={{
                                [PROHIBIT_DEROGATORY_LANGUAGE]: DEFAULT_FALSE_POSITIVE_VALUES[0],
                                [PROHIBIT_PROFANITY]: DEFAULT_FALSE_POSITIVE_VALUES[0],
                                [PROHIBIT_OFFENSIVE_LANGUAGE]: DEFAULT_FALSE_POSITIVE_VALUES[0],
                                [ADDITIONAL_REQUIREMENTS]: '',
                              }}
                              onSubmit={handleClickGenerateCompliance}
                              validationSchema={ValidationSchema}
                              ref={formRef}
                            >
                              {({ values, handleChange, touched, errors, setFieldValue }) => (
                                <Box width="100%" pb="m">
                                  <Box>
                                    <DropDown
                                      options={DEFAULT_FALSE_POSITIVE_VALUES}
                                      label="Prohibit derogatory language"
                                      value={values[PROHIBIT_DEROGATORY_LANGUAGE]}
                                      onChange={(option) => {
                                        setFieldValue(PROHIBIT_DEROGATORY_LANGUAGE, option)
                                      }}
                                      error={
                                        errors[PROHIBIT_DEROGATORY_LANGUAGE] &&
                                        touched[PROHIBIT_DEROGATORY_LANGUAGE] &&
                                        errors[PROHIBIT_DEROGATORY_LANGUAGE]
                                      }
                                      openMenuOnFocus
                                      height={pxToRem(40)}
                                    />
                                  </Box>

                                  <Box mt="m">
                                    <DropDown
                                      options={DEFAULT_FALSE_POSITIVE_VALUES}
                                      label="Prohibit profanity"
                                      value={values[PROHIBIT_PROFANITY]}
                                      onChange={(option) => {
                                        setFieldValue(PROHIBIT_PROFANITY, option)
                                      }}
                                      error={
                                        errors[PROHIBIT_PROFANITY] &&
                                        touched[PROHIBIT_PROFANITY] &&
                                        errors[PROHIBIT_PROFANITY]
                                      }
                                      openMenuOnFocus
                                      height={pxToRem(40)}
                                    />
                                  </Box>

                                  <Box mt="m">
                                    <DropDown
                                      options={DEFAULT_FALSE_POSITIVE_VALUES}
                                      label="Prohibit offensive language"
                                      value={values[PROHIBIT_OFFENSIVE_LANGUAGE]}
                                      onChange={(option) => {
                                        setFieldValue(PROHIBIT_OFFENSIVE_LANGUAGE, option)
                                      }}
                                      error={
                                        errors[PROHIBIT_OFFENSIVE_LANGUAGE] &&
                                        touched[PROHIBIT_OFFENSIVE_LANGUAGE] &&
                                        errors[PROHIBIT_OFFENSIVE_LANGUAGE]
                                      }
                                      openMenuOnFocus
                                      height={pxToRem(40)}
                                    />
                                  </Box>

                                  <Box mt="m">
                                    <TextArea
                                      label="Additional requirements"
                                      placeholder="Enter here additional requirements"
                                      values={values[ADDITIONAL_REQUIREMENTS]}
                                      onChange={handleChange(ADDITIONAL_REQUIREMENTS)}
                                      error={
                                        errors[ADDITIONAL_REQUIREMENTS] &&
                                        touched[ADDITIONAL_REQUIREMENTS] &&
                                        errors[ADDITIONAL_REQUIREMENTS]
                                      }
                                      width="100%"
                                      rows="4"
                                    />
                                  </Box>
                                </Box>
                              )}
                            </Formik>
                          </AccordionDetails>
                        </StyledAccordion>
                        <Flex flexDirection="column">
                          {compliances.map((compliance) => {
                            const { id, text } = compliance
                            return (
                              <StyledComplianceWrapper
                                mt="m"
                                key={id}
                                position="relative"
                                flexDirection="column"
                                width="100%"
                              >
                                <StyledGeneratedText color="primaryText">{text}</StyledGeneratedText>
                                <Flex justifyContent="flex-end">
                                  <StyledUseComplianceWrapper
                                    onClick={() => {
                                      handleClickUseCompliance({ text })
                                    }}
                                  >
                                    <Icon.HeartOutlined width="18px" height="16px" fill="primary" />
                                    <Text ml="s" color="primary">
                                      Use it
                                    </Text>
                                  </StyledUseComplianceWrapper>
                                </Flex>

                                <RemoveIconWrapper
                                  onClick={() => {
                                    handleClickRemoveGeneratedCompliance({ id })
                                  }}
                                >
                                  <Image width="10px" height="10px" src="/assets/clear.svg" />
                                </RemoveIconWrapper>
                              </StyledComplianceWrapper>
                            )
                          })}
                        </Flex>
                      </Flex>
                    </Scrollbars>
                    <Flex justifyContent="center" p="m">
                      {!usage || (usage && remaining > 0) ? (
                        <Flex alignItems="center" width="100%" justifyContent="center">
                          {compliances.length > 0 && (
                            <StyledText onClick={handleClickClearGeneratedCompliances} color="primary" mr="m">
                              Clear
                            </StyledText>
                          )}
                          <ButtonWithLoading
                            onClick={handleClickSubmitForm}
                            isSmall
                            type="button"
                            isLoading={isGettingData}
                          >
                            <Text>
                              {isGettingData
                                ? 'Generating'
                                : `${compliances.length > 0 ? 'Regenerate policy' : 'Generate policy'}  ${
                                    usage ? `(${remaining} ${remaining === 1 ? 'credit' : 'credits'} left)` : ''
                                  }`}
                            </Text>
                          </ButtonWithLoading>
                        </Flex>
                      ) : (
                        <Button.Primary
                          isSmall
                          onClick={() => {
                            window.location.replace('/pricing')
                          }}
                        >
                          0 credits left. Please upgrade
                        </Button.Primary>
                      )}
                    </Flex>
                  </Fragment>
                )}
              </Flex>
            </Flex>
          )}

          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

ComplianceModal.defaultProps = {}

ComplianceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
}

ComplianceModal.displayName = 'ComplianceModal'

export default ComplianceModal
