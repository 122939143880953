import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Router from 'next/router'
import { Scrollbars } from 'react-custom-scrollbars-2'
import moment from 'moment-timezone'
import { PROFILE_STATUSES } from 'consts'
import { radius } from 'theme'
import { hasEntitiesAccess } from 'utils/feature'
import { Flex } from 'components/atoms/Layout'
import Icon from 'components/atoms/Icon'
import { Text } from 'components/atoms/Typography'
import Button from 'components/atoms/Button'
import Filter from 'routes/Search/Filter'
import Tree from './Tree'
import SocialNetworksFilterTemplate from './EntitySelectorWrapper/SocialNetworksFilterTemplate'
import { BLOCKED_ENTITY_PROFILE_NETWORKS } from '../consts'
import { filterSocialProfiles } from '../helpers'

const StyledArrowWrapper = styled(Flex)`
  width: 32px;
  height: 32px;
  border-radius: ${radius.pill};
  cursor: pointer;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${({ theme }) => theme.colors.icon_background_hover};

    svg {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`

const StyledTrialEndingWrapper = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.border_color};
`

const EntitySelector = forwardRef(
  (
    {
      user,
      handleSaveSelectedEntities,
      handleSaveSelectedProfiles,
      canSelectLostAccessProfiles,
      blockedSocialProfileChecker,
      socialNetworkFilter,
      handleSaveEmptyProfiles,
      accessLevels,
      permission,
      setSocialNetworkFilter,
      handleClickOpenSocialNetworksFilterBody,
      handleClickOpenSelector,
      ...props
    },
    entitySelectorRef
  ) => {
    const [selectedEntities, setSelectedEntities] = useState([])
    const [selectedProfiles, setSelectedProfiles] = useState([])
    const [entities, setEntities] = useState([])
    const [trialLeftDays, setTrialLeftDays] = useState({ isTrial: false, days_string: '0 days', days: 0 })

    useImperativeHandle(entitySelectorRef, () => ({
      handleClickSelectAllProfiles({ clickType }) {
        // eslint-disable-next-line no-use-before-define
        return handleClickSelectAllProfiles({ clickType })
      },
    }))

    const filterSelectedProfilesByBlockedSocials = (data) => {
      let filteredData = data
      const removedSocialProfiles = []
      if (blockedSocialProfileChecker) {
        const leftSocialProfiles = []
        filteredData.forEach((item) => {
          const { blocked } = blockedSocialProfileChecker({
            profile: {
              network: { code: item.code, display: item.display },
              profile_url: item.profile_url,
              id: item.id,
            },
          })

          if (!BLOCKED_ENTITY_PROFILE_NETWORKS.includes(item.code)) {
            if (blocked) {
              removedSocialProfiles.push(item)
            } else {
              leftSocialProfiles.push(item)
            }
          }
        })
        filteredData = leftSocialProfiles
      }

      filteredData = filteredData.filter(({ entityId }) => {
        return !hasEntitiesAccess({
          user,
          permission,
          entity_gid: entityId,
          accessLevels,
        })
      })

      filteredData.filter(({ network: { code } = {} }) => !BLOCKED_ENTITY_PROFILE_NETWORKS.includes(code))

      if (!canSelectLostAccessProfiles) {
        filteredData = filteredData.filter(({ status }) => status !== PROFILE_STATUSES.BLOCKED)
      }
      return { filteredSocialProfiles: filteredData, removedSocialProfiles }
    }

    const filterHiddenNetworksFromProfiles = ({ data }) => {
      data.forEach((entity) => {
        if (entity.profiles && entity.profiles.length !== 0) {
          entity.profiles = entity.profiles.filter(
            ({ network: { code } = {} }) => !BLOCKED_ENTITY_PROFILE_NETWORKS.includes(code)
          )
        }
      })
    }

    const handleSaveAndUpdateSelectedProfiles = (data) => {
      const { filteredSocialProfiles, removedSocialProfiles } = filterSelectedProfilesByBlockedSocials(data)
      setSelectedProfiles([...filteredSocialProfiles])
      handleSaveSelectedProfiles({ data: filteredSocialProfiles, removedSocialProfiles })
    }

    useEffect(() => {
      if (props.selectedEntities.length !== 0) {
        filterHiddenNetworksFromProfiles({ data: props.selectedEntities })

        handleSaveSelectedEntities({ data: props.selectedEntities })
        setSelectedEntities([...props.selectedEntities])
      }
      if (props.selectedProfiles) {
        if (props.selectedProfiles.length !== 0) {
          const { filteredSocialProfiles, removedSocialProfiles } = filterSelectedProfilesByBlockedSocials(
            props.selectedProfiles
          )

          setSelectedProfiles([...filteredSocialProfiles])
          handleSaveSelectedProfiles({ data: filteredSocialProfiles, removedSocialProfiles })
        } else if (handleSaveEmptyProfiles) {
          handleSaveEmptyProfiles({})
        }
      } else {
        setSelectedProfiles([])
      }

      const { entity } = user
      const { subscription, plan } = entity || {}
      const { trial_end, first_payment_date } = subscription || {}
      const { name: plan_name } = plan || {}

      if (trial_end && plan_name !== 'Free' && !first_payment_date) {
        const left_days = moment.utc(trial_end, 'YYYY-MM-DD').diff(moment().utc(), 'days') + 1

        let days_string = '0 days'

        if (left_days >= 0) {
          days_string = `${left_days === 1 ? '1 day' : `${left_days} days`}`
        }

        setTrialLeftDays({ ...{ isTrial: true, days_string, days: left_days } })
      }
    }, [])

    useEffect(() => {
      setEntities([...props.entities])
    }, [props.entities])

    const handleClickSelectAllEntityProfiles = ({ clickType, entityId, entityType, profiles = [] }) => {
      const otherProfiles = selectedProfiles.filter((item) => item.entityId !== entityId)
      let entityProfilesTemp = []

      if (clickType === 'select') {
        entityProfilesTemp = filterSocialProfiles({ data: profiles, filter: true, socialNetworkFilter })
          .filter(
            ({ status, network: { code } = {} }) =>
              !BLOCKED_ENTITY_PROFILE_NETWORKS.includes(code) &&
              (canSelectLostAccessProfiles || (!canSelectLostAccessProfiles && status !== PROFILE_STATUSES.BLOCKED))
          )
          .map(
            ({
              name,
              username,
              isSample,
              id,
              picture_url,
              profile_url,
              network: { code, display } = {},
              status,
              scope,
              warning,
              profile_id,
              type,
              verified,
              custom_network_picture_url,
            }) => ({
              entityId,
              entityType,
              name,
              isSample,
              id,
              picture_url,
              profile_url,
              code,
              username,
              display,
              status,
              scope,
              warning,
              profile_id,
              type,
              verified,
              custom_network_picture_url,
            })
          )
      } else if (clickType === 'clear') {
        entityProfilesTemp = filterSocialProfiles({ data: profiles, filter: false, socialNetworkFilter })
      }
      const selectedProfilesTemp = [...entityProfilesTemp, ...otherProfiles]
      handleSaveAndUpdateSelectedProfiles(selectedProfilesTemp)
    }

    const handleSelectEntityDropdown = async ({ option, removed, options }) => {
      if (option) {
        const { id, name, type, timezone, profiles } = option || {}

        const accessView = hasEntitiesAccess({
          user,
          permission,
          entity_gid: id,
          accessLevels,
        })

        let updatedSelectedEntities = selectedEntities

        if (!removed) {
          updatedSelectedEntities.push({ name, id, type, timezone, profiles, isOpen: true })

          filterHiddenNetworksFromProfiles({ data: updatedSelectedEntities })
        } else {
          updatedSelectedEntities = updatedSelectedEntities.filter((item) => item.id !== id)
        }

        updatedSelectedEntities.sort((a, b) =>
          a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0
        )

        setSelectedEntities([...updatedSelectedEntities])
        handleSaveSelectedEntities({ data: updatedSelectedEntities })

        handleClickSelectAllEntityProfiles({
          clickType: removed || accessView ? 'removed' : 'select',
          entityId: id,
          entityType: type,
          profiles,
        })
      } else if (options) {
        if (options.length === 0) {
          setSelectedEntities([])
          handleSaveSelectedEntities({ data: [] })
          handleSaveAndUpdateSelectedProfiles([])
        } else {
          entities.sort((a, b) =>
            a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0
          )

          entities.forEach((entity) => {
            if (typeof entity.isOpen === 'undefined') {
              entity.isOpen = true
            }
          })

          filterHiddenNetworksFromProfiles({ data: entities })

          setSelectedEntities([...entities])
          handleSaveSelectedEntities({ data: entities })

          const entityProfilesTemp = []

          entities.forEach(({ id: entityId, type: entityType, profiles }) => {
            const accessMessage = hasEntitiesAccess({
              user,
              permission,
              entity_gid: entityId,
              accessLevels,
            })

            if (!accessMessage) {
              profiles.forEach(
                ({
                  name,
                  id,
                  username,
                  isSample,
                  picture_url,
                  profile_url,
                  network: { code, display } = {},
                  status,
                  scope,
                  warning,
                  profile_id,
                  type,
                  verified,
                }) => {
                  const data = {
                    entityId,
                    entityType,
                    name,
                    isSample,
                    id,
                    picture_url,
                    profile_url,
                    code,
                    display,
                    username,
                    status,
                    scope,
                    warning,
                    profile_id,
                    type,
                    verified,
                  }
                  if (
                    !BLOCKED_ENTITY_PROFILE_NETWORKS.includes(code) &&
                    (canSelectLostAccessProfiles ||
                      (!canSelectLostAccessProfiles && status !== PROFILE_STATUSES.BLOCKED))
                  ) {
                    entityProfilesTemp.push(data)
                  }
                }
              )
            }
          })

          handleSaveAndUpdateSelectedProfiles(entityProfilesTemp)
        }
      }
    }

    const handleClickSelectProfile = ({ ...data }) => {
      const { id, entityId } = data
      const profileIndex = selectedProfiles.findIndex((value) => value.id === id && value.entityId === entityId)
      if (profileIndex > -1) {
        selectedProfiles.splice(profileIndex, 1)
      } else if (
        canSelectLostAccessProfiles ||
        (!canSelectLostAccessProfiles && data.status !== PROFILE_STATUSES.BLOCKED)
      ) {
        selectedProfiles.push(data)
      }

      handleSaveAndUpdateSelectedProfiles(selectedProfiles)
    }

    const handleClickSelectAllProfiles = ({ clickType }) => {
      const entityProfilesTemp = []

      if (clickType === 'select') {
        selectedEntities.forEach(({ profiles, id: entityId, type: entityType }) => {
          filterSocialProfiles({ data: profiles, filter: true, socialNetworkFilter })
            .filter(
              ({ status, network: { code } = {} }) =>
                !BLOCKED_ENTITY_PROFILE_NETWORKS.includes(code) &&
                (canSelectLostAccessProfiles || (!canSelectLostAccessProfiles && status !== PROFILE_STATUSES.BLOCKED))
            )
            .forEach(
              ({
                name,
                username,
                isSample,
                id,
                picture_url,
                profile_url,
                network: { code, display } = {},
                status,
                scope,
                warning,
                profile_id,
                type,
                verified,
              }) => {
                entityProfilesTemp.push({
                  entityId,
                  entityType,
                  name,
                  isSample,
                  id,
                  picture_url,
                  profile_url,
                  code,
                  username,
                  display,
                  status,
                  scope,
                  warning,
                  profile_id,
                  type,
                  verified,
                })
              }
            )
        })
      }

      handleSaveAndUpdateSelectedProfiles(entityProfilesTemp)
    }

    return (
      <Flex flexDirection="column" height="100%" width="100%">
        <Flex alignItems="center" pl="m" pr="xs">
          <Flex flexDirection="column" width="100%">
            <Filter
              label="Select Profiles"
              placeholder="Search ..."
              value={selectedEntities}
              options={entities}
              onSelect={() => {}}
              handleClickSelectedOption={handleSelectEntityDropdown}
              showLabel={false}
              numberOfSelectedItems={selectedProfiles.length}
              maxMenuHeight={350}
              minOptionsWidth={218}
              showCloseIcon
            />
          </Flex>

          <Flex alignItems="center" ml="xs">
            <SocialNetworksFilterTemplate
              selectedEntities={selectedEntities}
              selectedProfiles={selectedProfiles}
              socialNetworkFilter={socialNetworkFilter}
              setSocialNetworkFilter={setSocialNetworkFilter}
              handleClickOpenSocialNetworksFilterBody={handleClickOpenSocialNetworksFilterBody}
              entitySelectorRef={entitySelectorRef}
            />
            <StyledArrowWrapper onClick={handleClickOpenSelector}>
              <Icon.VistaSocialDashboardArrow width="16px" height="16px" fill="primaryText" />
            </StyledArrowWrapper>
          </Flex>
        </Flex>

        <Flex mt="m" flexDirection="column" height="100%" pl="m">
          <Scrollbars universal autoHide>
            <Tree
              user={user}
              entities={selectedEntities}
              selectedProfiles={selectedProfiles}
              handleClickSelectProfile={handleClickSelectProfile}
              handleClickSelectAllProfiles={handleClickSelectAllEntityProfiles}
              canSelectLostAccessProfiles={canSelectLostAccessProfiles}
              blockedSocialProfileChecker={blockedSocialProfileChecker}
              socialNetworkFilter={socialNetworkFilter}
              accessLevels={accessLevels}
              permission={permission}
            />
          </Scrollbars>
        </Flex>

        {trialLeftDays.isTrial && (
          <StyledTrialEndingWrapper pt="m" flexDirection="column" alignItems="center">
            {trialLeftDays.days <= 0 ? (
              <Text color="primaryText">
                {trialLeftDays.days === 0 ? 'Your trial ends today' : 'Your trial has ended'}
              </Text>
            ) : (
              <Text color="primaryText">
                Your trial ends in{' '}
                <Text as="span" fontWeight="bold">
                  {trialLeftDays.days_string}
                </Text>
              </Text>
            )}

            <Flex width="100%" px="l" mt="s">
              <Flex width="100%" px="xs">
                <Button.Gradient
                  isSmall
                  onClick={() => {
                    Router.push('/settings/subscription')
                  }}
                  width="100%"
                >
                  Subscribe
                </Button.Gradient>
              </Flex>
            </Flex>
          </StyledTrialEndingWrapper>
        )}
      </Flex>
    )
  }
)

EntitySelector.defaultProps = {
  entities: [],
  selectedProfiles: null,
  selectedEntities: [],
  handleSaveSelectedEntities: () => {},
  handleSaveSelectedProfiles: () => {},
  canSelectLostAccessProfiles: false,
  handleSaveEmptyProfiles: null,
  blockedSocialProfileChecker: null,
}

EntitySelector.propTypes = {
  user: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  selectedProfiles: PropTypes.array,
  selectedEntities: PropTypes.array,
  entities: PropTypes.array,
  handleSaveSelectedEntities: PropTypes.func,
  handleSaveSelectedProfiles: PropTypes.func,
  canSelectLostAccessProfiles: PropTypes.bool,
  blockedSocialProfileChecker: PropTypes.func,
  socialNetworkFilter: PropTypes.object.isRequired,
  handleSaveEmptyProfiles: PropTypes.func,
  accessLevels: PropTypes.array.isRequired,
  permission: PropTypes.string.isRequired,
  setSocialNetworkFilter: PropTypes.func.isRequired,
  handleClickOpenSocialNetworksFilterBody: PropTypes.func.isRequired,
  handleClickOpenSelector: PropTypes.func.isRequired,
}

export default EntitySelector
